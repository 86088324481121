import React from "react"
// import serviceone from '../../../static/images/serviceone.png'

export default function sectiontwo() {
  return (
    <div>
      <section
        class="relative pb-16 lg:pb-24 lg:px-20 bg-white overflow-hidden justify-center"
        style={{ fontFamily: "poppins" }}
      >
        {/* <img class="absolute left-1/2 top-0 transform -translate-x-1/2" src={serviceone} alt=""/> */}
        {/* <div class="relative  container px-4 mx-auto">
          <p class="mb-6 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why RJAC ?
          </p>
          <h2 class=" text-2xl md:text-4xl text-center font-bold font-heading tracking-px-n leading-tight text-indigo-800">
            General and Health Insurance Consulting –
          </h2>

          <section class="bg-white pb-6 ">
            <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <section class="bg-white pb-6">
                <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 flex justify-between">
                  <div class="">
                    <h1 class="md:text-2xl md:mb-5">
                      General and Health Insurance Consulting
                    </h1>
                    <p class="mb-3 font-semibold">
                      We provide a range of services to Life Insurance
                      companies, these include
                    </p>
                    <ul>
                      <li>- Capital and Risk Modelling</li>
                      <li>- Product design</li>
                      <li>- Pricing</li>
                      <li>- Reserving</li>
                      <li>- Regulatory & compliance reporting</li>
                      <li>- Liability Adequacy Testing</li>
                      <li>- Financial Condition Reports</li>
                      <li>
                        - Reinsurance optimization, appropriateness and
                        sufficiency assessment
                      </li>
                      <li>- IFRS consulting</li>
                      <li>- Financial Due-Diligence services</li>
                    </ul>
                  </div>
                  <div class=" md:ml-32">
                    <h1 class="md:text-2xl md:mb-5">
                      Life Insurance Consulting
                    </h1>
                    <p class="mb-3 font-semibold">
                      We provide a range of services to Life Insurance
                      companies, these include
                    </p>
                    <ul>
                      <li>- Risk Assessment</li>
                      <li>- Product design & filing</li>
                      <li>- Pricing</li>
                      <li>- Financial Reporting</li>
                      <li>- Regulatory & compliance reporting</li>
                      <li>- Reinsurance optimization</li>
                      <li>- Analysis of Surplus calculations</li>
                      <li>- Embedded value calculations</li>
                      <li>- IFRS consulting</li>
                      <li>- Financial Due-Diligence services</li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div> */}

        <div class="container px-4 mx-auto">
          <p class="mb-6 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why RJAC ?
          </p>
          <h2 class="text-2xl md:text-4xl text-center font-bold font-heading tracking-px-n leading-tight text-indigo-800">
            General and Health Insurance Consulting
          </h2>

          <section class="bg-white pb-6">
            {/* <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h1 class="md:text-2xl md:mb-5">
                    General and Health Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Capital and Risk Modelling</li>
                    <li>- Product design</li>
                    <li>- Pricing</li>
                    <li>- Reserving</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Liability Adequacy Testing</li>
                    <li>- Financial Condition Reports</li>
                    <li>
                      - Reinsurance optimization, appropriateness and
                      sufficiency assessment
                    </li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
                <div>
                  <h1 class=" md:text-2xl md:mb-5">
                    Life Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Risk Assessment</li>
                    <li>- Product design & filing</li>
                    <li>- Pricing</li>
                    <li>- Financial Reporting</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Reinsurance optimization</li>
                    <li>- Analysis of Surplus calculations</li>
                    <li>- Embedded value calculations</li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="max-w-7xl mx-auto">
              <div class="text-center mb-8 mt-8">
                <h2 class="text-3xl font-bold text-blue-900 mb-8">
                  Actuarial Consulting and Valuation
                </h2>
                <p class="text-lg text-gray-600 text-justify">
                  RJ Actuaries & Consultants provides a comprehensive suite of
                  actuarial services, including actuarial valuation, risk
                  assessment, and strategic consulting. With a team of
                  experienced Qualified Actuaries, the company offers expertise
                  in areas such as insurance, health insurance, and general
                  insurance. The actuarial services cover a wide range of
                  domains, including product development and pricing, claims
                  reserves assessment, and regulatory compliance.
                </p>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                {/* <!-- General and Health Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                      General and Health Insurance Consulting
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Capital and Risk Modelling</li>
                      <li>Product design</li>
                      <li>Pricing</li>
                      <li>Reserving</li>
                      <li>Regulatory & compliance reporting</li>
                      <li>Liability Adequacy Testing</li>
                      <li>Financial Condition Reports</li>
                      <li>
                        Reinsurance optimization, appropriateness and
                        sufficiency assessment
                      </li>
                      <li>IFRS consulting</li>
                      <li>Financial Due-Diligence services</li>
                    </ul>
                  </div>
                </div>

                {/* <!-- Life Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                      Life Insurance Consulting
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Risk Assessment</li>
                      <li>Product design & filing</li>
                      <li>Pricing</li>
                      <li>Financial Reporting</li>
                      <li>Regulatory & compliance reporting</li>
                      <li>Reinsurance optimization</li>
                      <li>Analysis of Surplus calculations</li>
                      <li>Embedded value calculations</li>
                      <li>IFRS consulting</li>
                      <li>Financial Due-Diligence services</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* ......................................................... */}

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto">
                <div class="text-center">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                    Roles as an Actuary
                  </h2>
                  <p class="text-lg text-gray-600 mb-8">
                    RJ Actuaries & Consultants provides support for various
                    fields, including regulatory compliance, financial
                    reporting, and risk management. The company's team of
                    experienced Qualified Actuaries offers expertise in areas
                    such as insurance, health insurance, and general insurance
                    serving as:
                  </p>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Appointed Actuary
                      </h3>
                      <p class="text-gray-600">
                        Responsible for statutory duties and compliance.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Valuation Actuary
                      </h3>
                      <p class="text-gray-600">
                        Responsible for valuation of assets and liabilities.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Pricing Actuary
                      </h3>
                      <p class="text-gray-600">
                        Responsible for determining the pricing structure.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Consulting Actuary
                      </h3>
                      <p class="text-gray-600">
                        Provides consulting services on actuarial matters.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Peer-review Actuary
                      </h3>
                      <p class="text-gray-600">
                        Conducts peer reviews of actuarial work.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}
