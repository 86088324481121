import React from "react";
import Navbar from "../components/navbar";
import ServicesOne from "../components/ServicesOne/sectionone";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import LogoOne from "../../static/images/favicon.png";

import Sectiontwo from "../components/ServicesOne/sectiontwo";
import Sectionthree from "../components/ServicesOne/sectionthree";
import Footer from "../components/footer";

export default function serviceone() {
  return (
    <div>
      <div>
        <FloatingWhatsApp
          phoneNumber="+91 9930522133"
          accountName="RJ Actuaries & Consultants"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={LogoOne}
        />
      </div>
      <Navbar />
      <ServicesOne />
      <Sectiontwo />
      {/* <Sectionthree/> */}
      <Footer />
    </div>
  );
}
